<template>
  <div>
    <v-row class="d-flex justify-space-between align-center mx-2">
      <page-header :title="titlePage" :menu="menu" />
      <internal-menu :menuItems="menuItems" />
    </v-row>
    <v-snackbar
      v-model="snackbar.opened"
      :bottom="snackbar.y === 'bottom'"
      :color="snackbar.color"
      :left="snackbar.x === 'left'"
      :multi-line="snackbar.mode === 'multi-line'"
      :right="snackbar.x === 'right'"
      :timeout="snackbar.timeout"
      :top="snackbar.y === 'top'"
      :vertical="snackbar.mode === 'vertical'"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar.opened = false"
          >Ok</v-btn
        >
      </template>
    </v-snackbar>
    <v-row justify="center">
      <v-dialog v-model="searchReport" persistent max-width="900">
        <v-card>
          <v-toolbar color="primary" dark>
            <v-btn icon dark @click="searchReport = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Extrato Financeiro</v-toolbar-title>
          </v-toolbar>
          <v-container fluid>
            <v-card-text>
              <v-row>
                <v-col cols="4" class="mt-8">
                  <v-select
                    outlined
                    v-model="accountId"
                    :items="accounts"
                    item-value="id"
                    item-text="name"
                    label="Conta"
                  ></v-select>
                </v-col>
                <v-col cols="4" class="mt-8">
                  <v-text-field
                    outlined
                    v-model="startDate"
                    type="date"
                    label="Data Inicial"
                  ></v-text-field>
                </v-col>

                <v-col cols="4" class="mt-8">
                  <v-text-field
                    outlined
                    v-model="endDate"
                    type="date"
                    label="Data Final"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red" text @click="searchReport = false">
                CANCELAR
              </v-btn>
              <v-btn color="green" text @click="getItems()"> BUSCAR </v-btn>
            </v-card-actions>
          </v-container>
        </v-card>
      </v-dialog>
    </v-row>
    <v-container fluid>
      <v-card>
        <v-toolbar elevation="0" height="auto">
          <v-row>
            <v-col cols="12" md="4" class="mt-6">
              <v-select
                outlined
                v-model="accountId"
                :items="accounts"
                item-value="id"
                item-text="name"
                label="Conta"
              ></v-select>
            </v-col>
            <v-col cols="6" md="3" class="mt-6">
              <v-text-field
                outlined
                v-model="startDate"
                type="date"
                label="Data Inicial"
              ></v-text-field>
            </v-col>

            <v-col cols="6" md="3" class="mt-6">
              <v-text-field
                outlined
                v-model="endDate"
                type="date"
                label="Data Final"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="2" class="mt-8">
              <v-btn block dark color="green" outlined @click="getItems()">
                BUSCAR
              </v-btn>
            </v-col>
          </v-row>
        </v-toolbar>
        <v-data-table
          :loading="loading"
          :headers="headers"
          :items="movements"
          :search="search"
          hide-default-footer
          disable-pagination
        >
          <template v-slot:[`item.amount`]="{ item }">
            <span>R$ {{ formatPrice(item.amount, item.type) }}</span>
          </template>
          <template v-slot:[`item.paidDate`]="{ item }">
            <span>{{ dateFormat(item.paidDate) }}</span>
          </template>
        </v-data-table>
      </v-card>
      <br />
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>Resumo do Caixa</v-toolbar-title>
        </v-toolbar>
        <v-toolbar-items
          class="elevation-2"
          v-for="(credit, ic) in credits"
          :key="ic"
        >
          <v-row
            class="d-flex justify-space-between"
            style="
              padding-left: 20px !important;
              padding-right: 20px !important;
            "
          >
            <v-card-subtitle>{{ credit.name }} </v-card-subtitle>
            <v-card-subtitle
              >R$ {{ formatPrice(credit.amount, "C") }}</v-card-subtitle
            >
          </v-row>
        </v-toolbar-items>
        <v-toolbar-items
          class="elevation-2"
          v-for="(debit, id) in debits"
          :key="id"
        >
          <v-row
            class="d-flex justify-space-between"
            style="
              padding-left: 20px !important;
              padding-right: 20px !important;
            "
          >
            <v-card-subtitle>{{ debit.name }} </v-card-subtitle>
            <v-card-subtitle
              >R$ {{ formatPrice(debit.amount, "D") }}</v-card-subtitle
            >
          </v-row>
        </v-toolbar-items>
        <v-row>
          <v-col cols="12" sm="12" md="4">
            <v-toolbar-items class="elevation-2 green">
              <v-row
                class="d-flex justify-space-between"
                style="
                  padding-left: 20px !important;
                  padding-right: 20px !important;
                "
              >
                <v-card-title class="overline text-white"
                  >Entradas</v-card-title
                >
                <v-card-title class="overline text-white text-h6"
                  >R$ {{ formatPrice(totalCredit, "C") }}</v-card-title
                >
              </v-row>
            </v-toolbar-items>
          </v-col>
          <v-col cols="12" sm="12" md="4">
            <v-toolbar-items class="elevation-2 red">
              <v-row
                class="d-flex justify-space-between"
                style="
                  padding-left: 20px !important;
                  padding-right: 20px !important;
                "
              >
                <v-card-title class="overline text-white">Saídas</v-card-title>
                <v-card-title class="overline text-white text-h6"
                  >R$ {{ formatPrice(totalDebit, "D") }}</v-card-title
                >
              </v-row>
            </v-toolbar-items>
          </v-col>
          <v-col cols="12" sm="12" md="4">
            <v-toolbar-items class="elevation-2 primary">
              <v-row
                class="d-flex justify-space-between"
                style="
                  padding-left: 20px !important;
                  padding-right: 20px !important;
                "
              >
                <v-card-title class="overline text-white">Saldo</v-card-title>
                <v-card-title class="overline text-white text-h6"
                  >R$ {{ formatPrice(balance, "C") }}</v-card-title
                >
              </v-row>
            </v-toolbar-items>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </div>
</template>

<script>
/* eslint-disable */
import Vue from "vue";
import http from "@/services/http";
import moment from "moment";
import PageHeader from "@/components/core/PageHeader.vue";
import InternalMenu from "@/components/core/InternalMenu.vue";
export default Vue.extend({
  title: "FinancialMovement",
  components: { PageHeader, InternalMenu },
  data: () => ({
    titlePage: "Extrato Financeiro",
    menuItems: [
      {
        icon: "mdi-storefront",
        title: "Acesse",
        permission: "all",
        subs: [
          {
            icon: "mdi-storeftont",
            title: "Nova Receita Avista",
            to: "/finance/transaction/create?type=credit",
            permission: "gerentor.finance.transactions.create_one",
          },
          {
            icon: "mdi-storeftont",
            title: "Nova Receita Parcelada",
            to: "/finance/movement/instalments?type=credit",
            permission: "gerentor.finance.transactions.create_one",
          },
          {
            icon: "mdi-storeftont",
            title: "Nova Despesa Parcelada",
            to: "/finance/movement/instalments?type=debit",
            permission: "gerentor.finance.transactions.create_one",
          },
          {
            icon: "mdi-storeftont",
            title: "Nova Despesa Avista",
            to: "/finance/transaction/create?type=debit",
            permission: "gerentor.finance.transactions.create_one",
          },
          {
            icon: "mdi-storeftont",
            title: "Classificações Financeiras",
            to: "/finance/categories",
            permission: "gerentor.finance.categories.get_many",
          },
          {
            icon: "mdi-storeftont",
            title: "Métodos de Pagamento",
            to: "/finance/payment-methods",
            permission: "gerentor.finance.payment_methods.get_many",
          },
          {
            icon: "mdi-storeftont",
            title: "Extrato de Contas",
            to: "/finance/account-reports",
            permission: "gerentor.finance.payment_methods.get_many",
          },
        ],
      },
    ],
    menu: [
      {
        text: "Home",
        disabled: false,
        href: "/",
      },
      {
        text: "Transações",
        disabled: true,
        href: "/finance/transaction/instalment",
      },
    ],
    accounts: [],
    movements: [],
    totalCredit: 0.0,
    totalDebit: 0.0,
    balance: 0.0,
    credits: [],
    debits: [],
    searchReport: false,
    search: "",
    loading: false,
    loadingAction: false,
    snackbar: {
      color: "",
      mode: "",
      opened: false,
      text: "",
      timeout: 6000,
      x: null,
      y: "top",
    },
    money: {
      decimal: ",",
      thousands: ".",
      prefix: "R$ ",
      precision: 2,
      masked: false,
    },
    id: "",
    accountId: null,
    startDate: moment().format("yyyy-MM-DD"),
    endDate: moment().format("yyyy-MM-DD"),
    openDialog: false,
    headers: [
      {
        text: "Data/Hora",
        align: "start",
        value: "paidDate",
        filtering: true,
      },
      {
        text: "Descrição",
        align: "start",
        value: "title",
        filtering: true,
      },
      {
        text: "Tipo",
        align: "start",
        value: "type",
        filtering: true,
      },
      {
        text: "Valor R$",
        align: "start",
        value: "amount",
        filtering: true,
      },
    ],
  }),
  methods: {
    formatPrice(value, type) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return type === "C"
        ? val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        : "-" + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    dateFormat(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    getItems() {
      this.loading = true;
      http
        .get(
          `results/movements?accountId=${this.accountId}&startDate=${this.startDate}&endDate=${this.endDate}`
        )
        .then(
          (data) => {
            this.movements = data.data.movements;
            this.credits = data.data.credits;
            this.debits = data.data.debits;
            this.loading = false;
            this.searchReport = false;
          },
          () => (this.loading = false)
        );

      http
        .get(
          `results/balance?accountId=${this.accountId}&startDate=${this.startDate}&endDate=${this.endDate}`
        )
        .then((data) => {
          this.totalDebit = data.data.debit;
          this.totalCredit = data.data.credit;
          this.balance = data.data.balance;
        });
    },
    getAccounts() {
      this.loading = true;
      http.get("finance/accounts").then(
        (data) => {
          this.accounts = data.data;
          this.loading = false;
        },
        () => (this.loading = false)
      );
    },
  },
  mounted() {
    this.getAccounts();
  },
});
</script>
<style scoped>
.text-white {
  color: white !important;
}
</style>